<template>
  <div>
    <!-- <section class="overflow-hidden relative" style="height: 100vh">
      <div class="login-screeen-shape-1"></div>
      <div class="login-screen-header"></div>
      <div class="login-screen-footer"></div>
    </section> -->
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col justify-center items-center">
        <RouterLink
          :to="{
            name: 'Home',
          }"
        >
          <img
            :src="BRAND_CONFIG.ORG_DARK_LOGO"
            alt="Logo"
            :style="`width: ${BRAND_CONFIG.LOGO_SIZE.LOGIN_PAGE.WIDTH}px;`"
          />
        </RouterLink>
        <div class="mt-5 login-area ">
          <div class="px-12 my-2 w-full">
            <h1
              class="mt-5 mb-2 text-lg font-bold sm:text-xl md:text-2xl"
              style="color: #505458"
            >
              Create Account
            </h1>
            <div class="h-6 text-center text-red-500">
              <transition name="fade">
                <div
                  class="text-center text-red-500"
                  v-if="reqStatus === 'failed'"
                >
                  Invalid Credentials
                </div>
              </transition>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <div class="flex items-center pb-5">
                  <ValidationProvider
                    vid="profile_category"
                    name=""
                    rules="required"
                  >
                    <div class="flex">
                      <label class="flex items-center cursor-pointer">
                        <input
                          v-model="form.profile_category"
                          type="radio"
                          class="form-radio"
                          name="Profile"
                          value="company"
                        />
                        <span class="ml-2 text-sm">Company</span>
                      </label>

                      <label
                        class="flex items-center mt-px ml-4 cursor-pointer"
                      >
                        <input
                          v-model="form.profile_category"
                          type="radio"
                          class="p-1 form-radio"
                          name="Profile"
                          value="individual"
                        />
                        <span class="ml-2 text-sm">Individual</span>
                      </label>
                    </div>
                  </ValidationProvider>
                </div>

                <AppInput
                  v-if="form.profile_category === 'company'"
                  type="text"
                  rules="required"
                  label="Company Name"
                  placeholder="e.g. DUK"
                  name="Company Name"
                  v-model="form.company_name"
                />

                <AppInput
                  rules="required"
                  label="First Name"
                  placeholder="e.g. John"
                  name="First Name"
                  v-model="form.first_name"
                />
                <AppInput
                  rules="required"
                  label="Last Name"
                  placeholder="e.g. Doe"
                  name="Last Name"
                  v-model="form.last_name"
                />

                <AppInput
                  v-model="form.email"
                  type="email"
                  name="Email"
                  rules="required"
                  label="Email"
                  placeholder="e.g. john@example.com"
                />
                <AppInput
                  v-model="form.password"
                  type="password"
                  name="Password"
                  rules="required"
                  label="Password"
                />
                <div class="pb-6 mt-6">
                  <t-button
                    class="w-full relative bg-color text-primary-color"
                    type="submit"
                  >
                    <loading
                      v-if="isLoading"
                      :active="isLoading"
                      :height="25"
                      :opacity="0"
                      :color="`#fff`"
                      :loader="`bars`"
                      :is-full-page="false"
                      :class="`outline-none`"
                    />
                    <span v-else>Register</span>
                  </t-button>
                </div>
              </form>
            </ValidationObserver>
            <span
              >Already have an account?
              <RouterLink
                :to="{
                  name: 'Login',
                }"
              >
                <span class="font-bold">Log In</span>
              </RouterLink></span
            >
          </div>
        </div>
      </div>

      <!-- end:component -->
    </section>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { BRAND_CONFIG } from '@/config/brand.config'
import { xMan } from '@/utils'
import { TYPE, POSITION } from 'vue-toastification'
export default {
  name: 'Register',
  components: {},
  data() {
    return {
      BRAND_CONFIG: BRAND_CONFIG,
      form: {
        profile_category: 'company',
        company_name: null,
        first_name: null,
        last_name: null,
        email: null,
        password: null,
      },
      isLoading: false,
    }
  },

  methods: {
    async submit() {
      const formProxy = this.form
      if (formProxy.profile_category === 'individual') {
        this.form.company_name = null
        delete formProxy['company_name']
      }
      const data = new xMan(formProxy).toFormData()

      await this.$http
        .post(useEndpoints.auth.buyerRegister(), data)
        .then(() => {
          sessionStorage.setItem('user-email', this.form.email)
          sessionStorage.setItem('registration-data', JSON.stringify(formProxy))
          this.$router.push('/verify-email')
        })
        .catch((error) => {
          console.log('error-register', error.response.data)
          this.$toast(`${error}`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
// todo: @use "sass:math";

$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 40%;
  left: 50%;

  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  height: 70vh;
  border-radius: 0.375rem;
  overflow-y: auto;

  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}

.login-logo {
  width: $logo-width;
  height: $logo-height;
  object-fit: contain;
  z-index: 100;
}

.login-screen-header {
  background-image: url('~@/assets/otoride/auth/auth-header-v1-1x.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  height: 60vh;
}

.login-screeen-shape-1 {
  position: absolute;
  border: solid 38px #83ff00;
  right: -58px;
  top: -45px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.login-screen-footer {
  height: 40vh;
  width: 100%;
  border: 1px solid red;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 20rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/otoride/auth/auth-footer-v2-1x.png');
}

.bg-color {
  background: var(--brand-primary-color);
}

.text-primary-color {
  color: var(--button-text-primary-color);
}
</style>
